<template>
  <div>
    <div class="d-flex justify-content-start ms-5 mt-4">
      <div class="w-100 d-flex justify-content-start ml-8 pt-4">
        <h2>
          <span style="color: #14993c"> Español </span>
        </h2>
      </div>
    </div>
    <section class="experience py-0">
      <div
        class="__content mt-0 pb-0"
        v-for="section in sectionsES"
        :key="section.id"
      >
        <div class="__description wet-form">
          <h2>
            <input
              class="form-control form-control-lg"
              v-model="section.title"
            />
          </h2>
          <input class="form-control" v-model="section.description" />
        </div>
        <img
          class="w-100 d-none d-md-block mt-4 mb-2"
          :src="require(`../../assets/${section.img}`)"
          :alt="`Imagen de seccion ${section.title}`"
        />
        <img
          class="w-100 d-block d-md-none my-4"
          :src="require(`../../assets/${section.imgMobile}`)"
          :alt="`Imagen de seccion ${section.title} version movil`"
        />
      </div>
    </section>
    <div class="d-flex justify-content-start ms-5 mt-4">
      <div class="w-100 d-flex justify-content-start ml-8">
        <button class="btn btn-success btn-lg" @click="syncContent('ES')">
          Guardar
        </button>
      </div>
    </div>
    <hr class="pill mt-5" />
    <div class="d-flex justify-content-start ms-5 mt-4">
      <div class="w-100 d-flex justify-content-start ml-8 mt-4">
        <h2>
          <span style="color: #14993c"> English </span>
        </h2>
      </div>
    </div>
    <section class="experience mb-0 py-0">
      <div
        class="__content mb-3 pt-0"
        v-for="section in sectionsEN"
        :key="section.id"
      >
        <div class="__description wet-form">
          <h2>
            <input
              class="form-control form-control-lg"
              v-model="section.title"
            />
          </h2>
          <input class="form-control" v-model="section.description" />
        </div>
        <img
          class="w-100 mt-4"
          :src="require(`../../assets/${section.img}`)"
          :alt="`Imagen de seccion ${section.title}`"
        />
      </div>
    </section>
    <div class="d-flex justify-content-start ms-5">
      <div class="w-100 d-flex justify-content-start ml-8">
        <button class="btn btn-success btn-lg mb-5" @click="syncContent('EN')">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getSection, putSection } from "../../helpers/contenido";
import store from "../../store";
export default {
  name: "Experiencia",
  data() {
    return {
      sectionsES: [],
      sectionsEN: [],
    };
  },
  mounted() {
    this.fetchData();
    this.fetchContent();
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },
  methods: {
    fetchData() {
      if (store.state.sections.expertise) {
        this.data = store.state.sections.expertise.components;
      }
    },
    async fetchContent() {
      const section = await getSection("expertise");
      const sectionEs = section.filter((section) => section.language == "ES");
      const sectionEn = section.filter((section) => section.language == "EN");
      this.sectionEs = sectionEs;
      this.sectionEn = sectionEn;
      this.sectionsES = sectionEs[0].components.sections;
      this.sectionsEN = sectionEn[0].components.sections;
    },
    async syncContent(lan) {
      if (lan === "ES") {
        const words = {
          id: this.sectionEs[0]._id,
          section: "expertise",
          components: {
            sections: this.sectionsES,
          },
          language: "ES",
        };
        await putSection(words);
      } else {
        const words = {
          id: this.sectionEn[0]._id,
          section: "expertise",
          components: {
            sections: this.sectionsEN,
          },
          language: "EN",
        };
        await putSection(words);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.experience {
  align-items: center;
  background-image: url("../../assets/img/x-horizontal-wave.svg");
  background-size: contain;
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  padding: 5% 0;
  width: 100%;

  .__description {
    align-self: center;
    width: 100%;
    max-width: 70%;

    h3 {
      margin: 35px 0;
    }
  }

  .__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1366px;
    text-align: center;
    padding: 20px;
  }
}

hr.pill {
  height: 0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}

.ml-8 {
  margin-left: 8rem;
}

@media (min-width: 992px) {
}
</style>
